import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
// TODO: google fonts: <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Open+Sans%3A300%2C400%2C500%2C600%2C700%2C800%7CPlayfair+Display%7CRoboto%7CRaleway%7CSpectral%7CRubik"></link>

  
import '../assets/vendor/css/bootstrap.min.css';

import '../assets/vendor/css/font-awesome.min.css';
import '../assets/vendor/css/simple-line-icons.css';
import '../assets/vendor/css/icon-hs-style.css';
import '../assets/vendor/css/hamburgers.min.css';
import '../assets/vendor/css/animate.css';
import '../assets/vendor/css/slick.min.css';
import '../assets/vendor/css/slick-theme.min.css';

import '../assets/css/styles.op-architecture.css';


import '../assets/css/custom.css';
import '../assets/scss/styles.op-architecture.scss';

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'charset', content: 'utf-8' },
                { name: 'description', content: 'Faff-bey official website' },
                { name: 'keywords', content: 'Faff-Bey, Finnish Speed Metal, Trash, S/M Party, Grave, Should have stayed in the grave, Poko, Bad Vugum, Grave Records' },
                { name:'og:title', content:'Faff-bey'},
                { name:'og:type', content:'band'},
                { name:'og:url', content:'http://www.facebook.com/pages/Faff-bey/501894016494076'},
                { name:'og:image', content:'../assets/pics/fb_coverpic.jpeg'},
                { name:'og:site_name', content:'Faff-bey official pages'},
                { name:'fb:admins', content:'1336956572'},
              ]}
            >
              <html lang='en' />
            </Helmet>
            <div className={'page-top'}>{children}</div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
